import React from "react"
import PropTypes from "prop-types"

const Content = ({ title, content }) => {
  return (
    <>
      <header>
        <h1 className="page-title mv0 pt0-ns pt1 f2 measure serif">{title}</h1>
      </header>
      <div className="f45 lh-tight lh-copy-ns">
        <section
          className="main-content"
          dangerouslySetInnerHTML={{ __html: content }}
        ></section>
      </div>
    </>
  )
}

Content.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
}

export default Content
