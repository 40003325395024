import React from "react"
import PropTypes from "prop-types"
import Facebook from "../icons/facebook.svg"
import Twitter from "../icons/twitter.svg"
import Mail from "../icons/mail.svg"
import dayjs from "dayjs"
import LocalizedFormat from "dayjs/plugin/localizedFormat"
dayjs.extend(LocalizedFormat)

const SocialSharing = ({ canonicalUrl, date, title, description }) => {
  const encodeTitle = encodeURI(`boz - ${title}`)
  const encodeDesc = encodeURI(`${title} - ${description} - ${canonicalUrl}`)
  const formattedDate = dayjs(date).format("ll")
  return (
    <section id="centerFooter" className="mt4 tc cf">
      <p className="f7 mt4 mt5-l">
        <span className="light-silver">Published</span>
        <time className="db gray" dateTime={date}>
          {formattedDate}
        </time>
      </p>
      <section className="mt2">
        <a
          className="resp-sharing-button__link"
          href={`https://facebook.com/sharer/sharer.php?u=${canonicalUrl}`}
          target="_blank"
          rel="noopener noreferrer"
          aria-label=""
        >
          <div className="resp-sharing-button resp-sharing-button--facebook resp-sharing-button--small">
            <div
              aria-hidden="true"
              className="resp-sharing-button__icon resp-sharing-button__icon--solid"
            >
              <Facebook />
            </div>
          </div>
        </a>
        <a
          className="resp-sharing-button__link"
          href={`https://twitter.com/intent/tweet/?text=${encodeDesc}&amp;url=${canonicalUrl}`}
          target="_blank"
          rel="noopener noreferrer"
          aria-label=""
        >
          <div className="resp-sharing-button resp-sharing-button--twitter resp-sharing-button--small">
            <div
              aria-hidden="true"
              className="resp-sharing-button__icon resp-sharing-button__icon--solid"
            >
              <Twitter />
            </div>
          </div>
        </a>
        <a
          className="resp-sharing-button__link"
          href={`mailto:?subject=${encodeTitle}&body=${encodeDesc}`}
          target="_blank"
          rel="noopener noreferrer"
          aria-label=""
        >
          <div className="resp-sharing-button resp-sharing-button--email resp-sharing-button--small">
            <div
              aria-hidden="true"
              className="resp-sharing-button__icon resp-sharing-button__icon--solid"
            >
              <Mail />
            </div>
          </div>
        </a>
      </section>
    </section>
  )
}

SocialSharing.propTypes = {
  canonicalUrl: PropTypes.string,
  date: PropTypes.string,
}

export default SocialSharing
