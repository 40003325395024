import React, { useEffect } from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Content from "../components/Content"
import SocialSharing from "../components/SocialSharing"
import { applyTheme } from "../utils/themeSwitcher"

const Article = ({ pageContext }) => {
  const {
    isPage,
    date,
    html,
    title,
    slug,
    color,
    description,
    image,
    canonicalUrl,
  } = pageContext
  useEffect(() => {
    applyTheme(color)
  }, [color])

  return (
    <Layout>
      <SEO
        title={title}
        slug={slug}
        description={description}
        publishedAt={date}
        isArticle={!isPage}
        image={image}
      />
      <Content date={date} content={html} title={title} />
      {!isPage && (
        <SocialSharing
          canonicalUrl={canonicalUrl}
          date={date}
          title={title}
          description={description}
        />
      )}
    </Layout>
  )
}

export default Article
